<main class="main container webHamsterMainContainer">
    <style scoped lang="css">
        @import url("https://stackpath.bootstrapcdn.com/bootstrap/4.5.0/css/bootstrap.min.css");
    </style>

    <div class="row">
        <div class="mb-3 col-12 controlsColumn order-1">
            <div class="webHamsterControlsContainer">
                <h5>Controls</h5>
            </div>
        </div>
        <div class="col-lg-8 col-12 order-2 order-lg-3">
            <div class="webHamsterTerritoryConatiner territory"></div>
            <span class="alert alert-warning noGamePopup"
                >No hamstergame currently running. Start a game to see the
                terriroty.</span
            >
        </div>
        <div class="mt-3 col-12 col-lg-4 order-3 order-lg-2">
            <h5>Game log</h5>
            <div class="webHamsterGameLog"></div>
        </div>
    </div>
</main>
