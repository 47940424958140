<!--
  -- When using the result component make sure that the reference to the participation input is changed if the result changes
  -- e.g. by using Object.assign to trigger ngOnChanges which makes sure that the result is updated
-->
<ng-container [ngSwitch]="templateStatus" id="result">
    <ng-container *ngSwitchCase="ResultTemplateStatus.IS_BUILDING">
        <span class="text-primary">
            <fa-icon [icon]="faCircleNotch" size="lg" [spin]="true" class="me-2"></fa-icon>
            <span jhiTranslate="artemisApp.editor.building">Building...</span>
        </span>
    </ng-container>
    <ng-container *ngSwitchCase="ResultTemplateStatus.HAS_RESULT">
        <ng-container *ngIf="result">
            <fa-icon *ngIf="showIcon" class="result-score-icon" [ngClass]="textColorClass" [icon]="resultIconClass" size="lg"></fa-icon>
            <span [ngClass]="textColorClass" class="guided-tour result" id="result-score" (click)="showDetails(result)">
                <span class="guided-tour result" [ngbTooltip]="resultTooltip | artemisTranslate">
                    {{ resultString }}
                </span>
                <span class="result" [ngbTooltip]="result.completionDate | artemisDate"> ({{ result.completionDate | artemisTimeAgo }}) </span>
            </span>
            <span *ngIf="hasBuildArtifact() && participation.type === ParticipationType.PROGRAMMING">
                <a (click)="downloadBuildResult(participation.id)">
                    &nbsp; <fa-icon [icon]="faFile"></fa-icon>
                    <span jhiTranslate="artemisApp.editor.downloadBuildResult">Download Build Result</span>
                </a>
            </span>
            <span *ngIf="showBadge" class="badge" [ngClass]="badgeClass" id="result-score-badge" ngbTooltip="{{ badgeTooltip | artemisTranslate }}">
                {{ badgeText | artemisTranslate | uppercase }}
            </span>
        </ng-container>
    </ng-container>
    <ng-container *ngSwitchCase="ResultTemplateStatus.LATE">
        <span [ngClass]="textColorClass">
            <fa-icon [icon]="resultIconClass" size="lg"></fa-icon>
            <span class="score"> {{ resultString }}, </span>
            <span class="text-muted">{{ 'artemisApp.courseOverview.exerciseList.exerciseLateFeedback' | artemisTranslate }}</span>
        </span>
    </ng-container>
    <ng-container *ngSwitchCase="ResultTemplateStatus.SUBMITTED">
        <span class="text-muted">{{ 'artemisApp.courseOverview.exerciseList.exerciseSubmitted' | artemisTranslate }}</span>
    </ng-container>
    <ng-container *ngSwitchCase="ResultTemplateStatus.SUBMITTED_WAITING_FOR_GRADING">
        <span class="text-muted">{{ 'artemisApp.courseOverview.exerciseList.exerciseSubmittedWaitingForGrading' | artemisTranslate }}</span>
    </ng-container>
    <ng-container *ngSwitchCase="ResultTemplateStatus.LATE_NO_FEEDBACK">
        <span class="text-muted">{{ 'artemisApp.courseOverview.exerciseList.exerciseLateSubmission' | artemisTranslate }}</span>
    </ng-container>
    <ng-container *ngSwitchCase="ResultTemplateStatus.MISSING">
        <span class="text-danger">
            <fa-icon [icon]="faExclamationCircle" size="lg"></fa-icon>&nbsp;
            <ng-container [ngSwitch]="missingResultInfo">
                <ng-container *ngSwitchCase="MissingResultInfo.FAILED_PROGRAMMING_SUBMISSION_ONLINE_IDE">
                    <span [ngbTooltip]="'artemisApp.result.missing.programmingFailedSubmission.tooltipOnlineIde' | artemisTranslate">{{
                        'artemisApp.result.missing.programmingFailedSubmission.message' | artemisTranslate
                    }}</span>
                </ng-container>
                <ng-container *ngSwitchCase="MissingResultInfo.FAILED_PROGRAMMING_SUBMISSION_OFFLINE_IDE">
                    <span [ngbTooltip]="'artemisApp.result.missing.programmingFailedSubmission.tooltipOfflineIde' | artemisTranslate">{{
                        'artemisApp.result.missing.programmingFailedSubmission.message' | artemisTranslate
                    }}</span>
                </ng-container>
            </ng-container>
            <span *ngIf="result" class="result" (click)="showDetails(result)">{{ 'artemisApp.result.missing.viewPrevious' | artemisTranslate }}</span>
        </span>
    </ng-container>
    <ng-container *ngSwitchDefault>
        <span class="text-muted">
            <fa-icon [icon]="farCircle" size="lg"></fa-icon>&nbsp;
            <span> {{ (showUngradedResults ? 'artemisApp.result.noResult' : 'artemisApp.result.noGradedResult') | artemisTranslate }}</span>
        </span>
    </ng-container>
</ng-container>
