<div *ngIf="currentTourStep">
    <div *ngIf="!selectedElementRect; else selectedElementOverlay">
        <div class="guided-tour-overlay" (click)="backdropClick($event)"></div>
    </div>
</div>
<div *ngIf="currentTourStep">
    <div
        #tourStep
        *ngIf="currentTourStep"
        class="tour-step tour-{{ orientation }}"
        [class.page-tour-step]="!currentTourStep.highlightSelector"
        [class.startFade]="startFade"
        [class.video-tour]="isVideoTourStep()"
        [style.top.px]="topPosition"
        [style.left.px]="leftPosition"
        [style.width.px]="calculatedTourStepWidth"
        [style.transform]="transform"
    >
        <div *ngIf="currentTourStep.highlightSelector" class="tour-arrow"></div>
        <div class="tour-block">
            <div class="tour-block__header">
                <h3 class="headline" *ngIf="currentTourStep.headlineTranslateKey">
                    <span *ngIf="!guidedTourService.isOnResizeMessage && guidedTourService.currentTour && guidedTourService.currentTour.steps.length > 1"
                        >{{ 'tour.step' | artemisTranslate : { string: guidedTourService.getCurrentStepString() } }} </span
                    >{{ currentTourStep.headlineTranslateKey | artemisTranslate }}
                </h3>
                <div
                    *ngIf="guidedTourService.currentTour"
                    class="btn-close"
                    (click)="
                        guidedTourService.isCurrentTour(cancelTour) || guidedTourService.isCurrentTour(completedTour) ? guidedTourService.resetTour() : guidedTourService.skipTour()
                    "
                ></div>
            </div>
            <div class="tour-block__content">
                <h5 jhiTranslate="{{ currentTourStep.subHeadlineTranslateKey }}" class="sub-headline" *ngIf="currentTourStep.subHeadlineTranslateKey"></h5>
                <div [innerHTML]="currentTourStep.contentTranslateKey | artemisTranslate"></div>
                <div *ngIf="currentTourStep.hintTranslateKey" class="step-hint">
                    <div class="step-hint__icon">
                        <fa-icon [icon]="faInfoCircle"></fa-icon>
                    </div>
                    <div class="step-hint__label">
                        <div [innerHTML]="currentTourStep.hintTranslateKey | artemisTranslate"></div>
                    </div>
                </div>
                <div *ngIf="currentTourStep.alreadyExecutedTranslateKey" class="step-hint interaction alert alert-success">
                    <div class="step-hint__icon">
                        <fa-icon [icon]="faCheck"></fa-icon>
                    </div>
                    <div class="step-hint__label">
                        <div [innerHTML]="currentTourStep.alreadyExecutedTranslateKey | artemisTranslate"></div>
                    </div>
                </div>
                <div
                    *ngIf="currentTourStep.userInteractionEvent && !currentTourStep.modelingTask"
                    class="step-hint interaction alert"
                    [class.alert-success]="userInteractionFinished"
                >
                    <div class="step-hint__icon" *ngIf="this.userInteractionFinished; else userInteractionIcons">
                        <fa-icon [icon]="faCheck"></fa-icon>
                    </div>
                    <ng-template #userInteractionIcons>
                        <div class="step-hint__icon">
                            <fa-icon *ngIf="currentTourStep.userInteractionEvent === UserInteractionEvent.CLICK" [icon]="faHandPointUp"></fa-icon>
                            <fa-icon *ngIf="currentTourStep.userInteractionEvent === UserInteractionEvent.ACE_EDITOR" [icon]="faICursor"></fa-icon>
                            <fa-icon *ngIf="currentTourStep.userInteractionEvent === UserInteractionEvent.WAIT_FOR_SELECTOR" [spin]="true" [icon]="faCircleNotch"></fa-icon>
                            <fa-icon *ngIf="currentTourStep.userInteractionEvent === UserInteractionEvent.MODELING && !currentTourStep.modelingTask" [icon]="faArrowsAlt"></fa-icon>
                            <fa-icon *ngIf="currentTourStep.userInteractionEvent === UserInteractionEvent.ASSESS_SUBMISSION" [icon]="faEdit"></fa-icon>
                        </div>
                    </ng-template>
                    <div class="step-hint__label">
                        <span *ngIf="currentTourStep.userInteractionEvent === UserInteractionEvent.CLICK" [jhiTranslate]="'tour.clickHint.text'"></span>
                        <span *ngIf="currentTourStep.userInteractionEvent === UserInteractionEvent.ACE_EDITOR" [jhiTranslate]="'tour.typeHint.text'"></span>
                        <span *ngIf="currentTourStep.userInteractionEvent === UserInteractionEvent.WAIT_FOR_SELECTOR" [jhiTranslate]="'tour.waitHint.text'"></span>
                        <span
                            *ngIf="currentTourStep.userInteractionEvent === UserInteractionEvent.MODELING && !currentTourStep.modelingTask"
                            [jhiTranslate]="'tour.modelingHint.text'"
                        ></span>
                        <span
                            *ngIf="currentTourStep.userInteractionEvent === UserInteractionEvent.ASSESS_SUBMISSION"
                            [jhiTranslate]="currentTourStep.assessmentTask.taskTranslateKey"
                        ></span>
                    </div>
                </div>
                <div *ngIf="currentTourStep.modelingTask" class="step-hint interaction alert" [class.alert-success]="userInteractionFinished">
                    <div class="step-hint__icon" *ngIf="!userInteractionFinished">
                        <fa-icon [icon]="faClipboardList"></fa-icon>
                    </div>
                    <div class="step-hint__icon" *ngIf="userInteractionFinished">
                        <fa-icon [icon]="faCheck"></fa-icon>
                    </div>
                    <div class="step-hint__label">
                        <div [innerHTML]="currentTourStep.modelingTask.taskTranslateKey | artemisTranslate"></div>
                    </div>
                </div>
                <div *ngIf="currentTourStep.imageUrl">
                    <img src="{{ currentTourStep.imageUrl }}" />
                </div>
                <div *ngIf="currentTourStep.videoUrl" class="step-hint interaction alert">
                    <div class="step-hint__icon">
                        <fa-icon [icon]="faVideo"></fa-icon>
                    </div>
                    <div class="step-hint__label">
                        <span jhiTranslate="tour.videoHint.text"></span>
                    </div>
                </div>
                <div *ngIf="currentTourStep.videoUrl">
                    <iframe [src]="currentTourStep.videoUrl | artemisTranslate | safeResourceUrl" frameborder="0" allowfullscreen></iframe>
                </div>
            </div>
            <div class="tour-block__buttons">
                <button
                    *ngIf="!guidedTourService.isCurrentTour(cancelTour) && !guidedTourService.isCurrentTour(completedTour) && !guidedTourService.isOnResizeMessage"
                    class="back-button"
                    [disabled]="guidedTourService.isOnFirstStep"
                    (click)="guidedTourService.backStep()"
                >
                    <fa-icon [icon]="faChevronLeft"></fa-icon>
                    <span jhiTranslate="tour.navigation.back"></span>
                </button>
                <button
                    *ngIf="guidedTourService.isCurrentTour(completedTour)"
                    class="restart-button"
                    [disabled]="guidedTourService.isOnResizeMessage || guidedTourService.restartIsLoading"
                    (click)="guidedTourService.restartTour()"
                >
                    <fa-icon *ngIf="!guidedTourService.restartIsLoading" [icon]="faPlayCircle"></fa-icon>
                    <fa-icon class="jhi-btn__loading" *ngIf="guidedTourService.restartIsLoading" [icon]="faCircleNotch" [spin]="true" size="sm"></fa-icon>
                    <span jhiTranslate="global.menu.restartTutorial"></span>
                </button>
                <div class="dotstyle dotstyle--scaleup" *ngIf="!guidedTourService.isOnResizeMessage">
                    <ul
                        #dotNavigation
                        *ngIf="guidedTourService.currentTour && guidedTourService.currentTour.steps.length > 1"
                        [ngStyle]="{ transform: 'translateX(' + transformX + 'px)' }"
                    >
                        <li
                            #dotElements
                            class="dot-index-{{ guidedTourService.currentTour.steps.indexOf(i) }}"
                            [class.current]="guidedTourService.isCurrentStep(i)"
                            [class.n-small]="calculateNSmallDot(guidedTourService.currentTour.steps.indexOf(i) + 1)"
                            [class.p-small]="calculatePSmallDot(guidedTourService.currentTour.steps.indexOf(i) + 1)"
                            *ngFor="let i of guidedTourService.currentTour.steps"
                        >
                            <span>{{ guidedTourService.currentTour.steps.indexOf(i) }}</span>
                        </li>
                    </ul>
                </div>
                <button
                    *ngIf="!guidedTourService.isOnLastStep && !guidedTourService.isOnResizeMessage"
                    class="next-button"
                    [disabled]="currentTourStep.userInteractionEvent && !userInteractionFinished"
                    (click)="guidedTourService.nextStep()"
                >
                    <span jhiTranslate="tour.navigation.next"></span>
                    <fa-icon [icon]="faChevronRight"></fa-icon>
                </button>
                <button
                    *ngIf="
                        guidedTourService.isOnLastStep &&
                        !guidedTourService.isCurrentTour(cancelTour) &&
                        !guidedTourService.isCurrentTour(completedTour) &&
                        !guidedTourService.isOnResizeMessage
                    "
                    class="next-button"
                    jhiTranslate="tour.navigation.done"
                    [disabled]="currentTourStep.userInteractionEvent && !userInteractionFinished"
                    (click)="guidedTourService.nextStep()"
                ></button>
                <button
                    *ngIf="guidedTourService.isOnResizeMessage || guidedTourService.isCurrentTour(cancelTour) || guidedTourService.isCurrentTour(completedTour)"
                    class="next-button"
                    jhiTranslate="tour.navigation.close"
                    (click)="guidedTourService.resetTour()"
                ></button>
            </div>
        </div>
    </div>
</div>
<ng-template #selectedElementOverlay>
    <div class="guided-tour-overlay" [ngStyle]="getOverlayStyle(OverlayPosition.TOP) || null" (click)="backdropClick($event)"></div>
    <div class="guided-tour-overlay" [ngStyle]="getOverlayStyle(OverlayPosition.LEFT) || null" (click)="backdropClick($event)"></div>
    <div class="guided-tour-overlay" [ngStyle]="getOverlayStyle(OverlayPosition.RIGHT) || null" (click)="backdropClick($event)"></div>
    <div class="guided-tour-overlay" [ngStyle]="getOverlayStyle(OverlayPosition.BOTTOM) || null" (click)="backdropClick($event)"></div>
    <div
        class="guided-tour-element-overlay"
        [class.click-through]="currentTourStep.userInteractionEvent"
        [ngStyle]="getOverlayStyle(OverlayPosition.ELEMENT) || null"
        (click)="backdropClick($event)"
    ></div>
</ng-template>
