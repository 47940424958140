<div class="pointer">
    <button class="guided-tour-notification notification-button light-button" (click)="toggleSidebar(); updateLastNotificationRead()">
        <fa-icon [icon]="faBell"></fa-icon>
        <span class="badge bg-danger rounded-pill" *ngIf="recentNotificationCount > 0">
            {{ recentNotificationCount }}<span *ngIf="recentNotificationCount >= notifications.length">+</span>
        </span>
    </button>
</div>

<div class="notification-overlay" (click)="toggleSidebar()" [ngClass]="showSidebar ? 'show' : 'hide'"></div>

<div class="notification-sidebar" [ngClass]="showSidebar ? 'show' : 'hide'">
    <div class="d-flex flex-column">
        <div class="header pt-4 px-3">
            <div class="d-flex header-icon justify-content-between align-items-baseline">
                <a class="ps-2" [routerLink]="['/user-settings/notifications']" (click)="toggleSidebar()">
                    <fa-icon class="ng-fa-icon" [icon]="faCog"></fa-icon>
                </a>
                <h5 class="mb-3 text-center fw-medium">
                    {{ 'artemisApp.notification.notifications' | artemisTranslate }}
                </h5>
                <button class="close header-icon border-0 bg-transparent pe-2" (click)="toggleSidebar()">
                    <fa-icon class="ng-fa-icon" [icon]="faTimes"></fa-icon>
                </button>
            </div>
            <div class="text-end">
                <button id="hide-until-toggle" class="border-0 bg-transparent pe-2" (click)="toggleNotificationDisplay()">
                    <fa-icon
                        *ngIf="showButtonToHideCurrentlyDisplayedNotifications; else showButtonToDisplayAllNotifications"
                        class="ng-fa-icon"
                        [icon]="faArchive"
                        [ngbTooltip]="'artemisApp.notification.hideAllCurrentlyDisplayedNotifications' | artemisTranslate"
                    >
                    </fa-icon>
                    <ng-template #showButtonToDisplayAllNotifications>
                        <fa-icon class="ng-fa-icon" [icon]="faEye" [ngbTooltip]="'artemisApp.notification.showAllSavedNotifications' | artemisTranslate"> </fa-icon>
                    </ng-template>
                </button>
                <small class="text-muted">
                    {{ 'artemisApp.notification.loadedNotificationCount' | artemisTranslate : { loaded: sortedNotifications?.length || '0', total: totalNotifications } }}
                </small>
            </div>
        </div>
        <div id="notification-sidebar-container" (scroll)="onScroll()">
            <div class="alert alert-danger mx-3" role="alert" *ngIf="error">
                {{ 'artemisApp.notification.unexpectedError' | artemisTranslate }}
            </div>
            <div class="notification-item p-3" *ngFor="let notification of sortedNotifications" (click)="startNotification(notification)">
                <div>
                    <h5 class="fw-medium">
                        {{ notification.title }}
                        <span class="badge ms-1 bg-primary" *ngIf="lastNotificationRead && notification.notificationDate?.isAfter(lastNotificationRead)"> new </span>
                    </h5>
                    <div class="mb-1 text-break" [innerHTML]="notification.text"></div>
                    <div class="info text-muted text-end">
                        {{ notification.notificationDate?.toDate() | date : 'dd.MM.yy HH:mm' }}
                        {{ 'artemisApp.notification.by' | artemisTranslate }}
                        <span *ngIf="notification.author; else noAuthor">{{ notification.author.name }}</span>
                        <ng-template #noAuthor>
                            <span jhiTranslate="global.title"></span>
                        </ng-template>
                    </div>
                </div>
            </div>
            <div class="loading-spinner text-center mx-3 my-2" style="font-size: large" *ngIf="loading">
                <fa-icon [icon]="faCircleNotch" [spin]="true"></fa-icon>
            </div>
            <small class="all-loaded text-center d-block mx-3 my-2" *ngIf="notifications.length > 0 && notifications.length >= totalNotifications">
                {{ 'artemisApp.notification.allLoaded' | artemisTranslate }}
            </small>
            <span class="no-notifications" *ngIf="notifications && notifications.length === 0">
                {{ 'artemisApp.notification.noNotifications' | artemisTranslate }}
            </span>
        </div>
    </div>
</div>
