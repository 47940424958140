<div class="dismiss-all" *ngIf="(alerts?.length ?? 0) >= 2" @alertAnimation>
    <div class="btn btn-secondary" (click)="alertService.closeAll()">
        <fa-icon [icon]="times"></fa-icon>
        <span jhiTranslate="artemisApp.alerts.dismissAll">Dismiss all</span>
    </div>
</div>
<div class="alert-wrap" *ngFor="let alert of alerts" @alertAnimation>
    <div class="alert-inner" [ngClass]="alert.type.containerClassName">
        <div class="left">
            <fa-icon [icon]="alert.type.icon" size="2x"></fa-icon>
            <div class="message" [innerHTML]="alert.message"></div>
        </div>
        <button *ngIf="alert.action" type="button" [ngClass]="'btn ' + alert.type.buttonClassName" (click)="alert.action.callback(alert)">{{ alert.action.label }}</button>
        <jhi-close-circle *ngIf="alert.dismissible" (click)="alert.close()"></jhi-close-circle>
        <div class="time-bar" *ngIf="(alert?.timeout || 0) > 0" [ngStyle]="{ animationDuration: alert.timeout + 'ms' }"></div>
    </div>
</div>
