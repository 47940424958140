<div class="modal-header">
    <h4 class="modal-title">
        <ng-container *ngIf="taskName; else noTaskDescription">{{ 'artemisApp.result.detail.feedbackForTask' | artemisTranslate : { taskName } }}</ng-container>
        <ng-template #noTaskDescription>{{ 'artemisApp.result.detail.feedback' | artemisTranslate }}</ng-template>
    </h4>
    <button type="button" class="btn-close" data-dismiss="modal" aria-hidden="true" (click)="activeModal.close()"></button>
</div>
<div class="modal-body position-relative" id="result-detail-body">
    <!-- Message, if defined -->
    <div *ngIf="messageKey" class="mb-3"><h6 [innerHTML]="messageKey | artemisTranslate"></h6></div>
    <div *ngIf="showMissingAutomaticFeedbackInformation" class="mb-3">
        <h6 [innerHTML]="'artemisApp.result.afterDueDateFeedbackHidden' | artemisTranslate : { date: latestIndividualDueDate }"></h6>
    </div>
    <!-- Loading state -->
    <div *ngIf="isLoading; else feedbackContainer" class="text-muted d-flex justify-content-center" id="result-detail-spinner">
        <fa-icon [spin]="true" size="lg" [icon]="faCircleNotch"></fa-icon>
    </div>
    <!-- Feedbacks container -->
    <ng-template #feedbackContainer>
        <div *ngIf="!loadingFailed && filteredFeedbackList && filteredFeedbackList.length; else buildLogsContainer" class="result-detail-container">
            <div *ngIf="showScoreChart && result.participation?.exercise" class="result-score-chart">
                <span class="result-score-chart-title">
                    <span *ngIf="!exercise?.maxPoints; else scoreWithPoints">
                        {{ 'artemisApp.result.score' | artemisTranslate : { score: roundValueSpecifiedByCourseSettings(result.score, course) } }}
                    </span>
                    <ng-template #scoreWithPoints>
                        <span *ngIf="result.score && exercise && exercise.maxPoints">
                            {{
                                'artemisApp.result.scoreWithPoints'
                                    | artemisTranslate
                                        : {
                                              score: roundValueSpecifiedByCourseSettings(result.score, course),
                                              points: roundValueSpecifiedByCourseSettings((result.score * exercise.maxPoints) / 100, course)
                                          }
                            }}
                        </span>
                    </ng-template>
                </span>
                <div id="feedback-chart" #containerRef class="chart-space">
                    <ngx-charts-bar-horizontal-stacked
                        [view]="[containerRef.offsetWidth, 80]"
                        [results]="ngxData"
                        [xAxis]="true"
                        [xAxisTickFormatting]="xAxisFormatting"
                        [xScaleMax]="xScaleMax"
                        [legend]="true"
                        [legendTitle]="''"
                        [legendPosition]="legendPosition"
                        [tooltipDisabled]="true"
                        [scheme]="ngxColors"
                        (select)="onSelect($event)"
                    >
                    </ngx-charts-bar-horizontal-stacked>
                </div>
                <div *ngIf="showScoreChartTooltip" class="chart-tooltip-space d-flex justify-content-center position-relative">
                    <div class="chart-tooltip-space-content" [ngbTooltip]="'artemisApp.result.chart.tooltip' | artemisTranslate" placement="bottom auto"></div>
                </div>
                <hr />
            </div>
            <!-- Always show failed build logs first -->
            <ng-container *ngTemplateOutlet="buildLogs?.length ? buildLogsContainer : null"></ng-container>
            <hr *ngIf="buildLogs?.length" />
            <div class="feedback-list">
                <div class="mb-2 d-flex justify-content-center align-items-center" *ngIf="showOnlyNegativeFeedback || showOnlyPositiveFeedback">
                    <fa-icon [icon]="faExclamationTriangle" class="text-warning"></fa-icon>
                    <span class="ms-1" jhiTranslate="artemisApp.result.chart.filterApplied">A filter is applied to your feedback</span>
                    <button type="button" class="ms-2 btn btn-info" (click)="resetChartFilter()" jhiTranslate="artemisApp.result.chart.reset">Reset</button>
                </div>
                <div *ngIf="!loadingFailed && resultIsPreliminary(result)">
                    <div class="d-flex justify-content-end m-1">
                        <div class="badge bg-warning">{{ 'artemisApp.result.preliminary' | artemisTranslate | uppercase }}</div>
                    </div>
                    <p *ngIf="exercise?.assessmentType !== AssessmentType.AUTOMATIC" jhiTranslate="artemisApp.result.preliminaryTooltipSemiAutomatic"></p>
                    <p *ngIf="exercise?.assessmentType === AssessmentType.AUTOMATIC" jhiTranslate="artemisApp.result.preliminaryTooltip"></p>
                </div>
                <div *ngIf="testCaseCount || numberOfNotExecutedTests || scaFeedbackCount || manualFeedbackCount" class="mb-2">
                    <div *ngIf="testCaseCount">
                        {{ 'artemisApp.result.testCount' | artemisTranslate : { passed: passedTestCaseCount, total: testCaseCount } }}
                    </div>
                    <div *ngIf="numberOfNotExecutedTests">
                        {{ 'artemisApp.result.notExecutedTests' | artemisTranslate : { numberOfNotExecutedTests } }}
                        <jhi-help-icon text="artemisApp.result.notExecutedTestsTooltip"></jhi-help-icon>
                    </div>
                    <div *ngIf="scaFeedbackCount">
                        {{ 'artemisApp.result.scaIssueCount' | artemisTranslate : { issues: scaFeedbackCount } }}
                    </div>
                    <div *ngIf="manualFeedbackCount">
                        {{ 'artemisApp.result.manualFeedbackCount' | artemisTranslate : { feedbacks: manualFeedbackCount } }}
                    </div>
                </div>
                <div *ngFor="let feedback of filteredFeedbackList" [ngClass]="['feedback-item', 'alert', getClassNameForFeedbackItem(feedback)]" id="feedback-message">
                    <span>{{ feedback.category }}</span>
                    <span id="feedback-points" class="feedback-points" *ngIf="feedback.credits">
                        {{ roundValueSpecifiedByCourseSettings(feedback.actualCredits ?? feedback.credits, course) }}P
                        <fa-icon
                            *ngIf="feedback.type === FeedbackItemType.Subsequent"
                            [icon]="faExclamationTriangle"
                            [ngbTooltip]="'artemisApp.assessment.subsequentFeedback' | artemisTranslate"
                        ></fa-icon>
                        <fa-icon
                            *ngIf="feedback.type === FeedbackItemType.Issue && feedback.actualCredits !== feedback.credits"
                            [icon]="faExclamationTriangle"
                            [ngbTooltip]="'artemisApp.programmingAssessment.codeIssueNotApplied' | artemisTranslate : { points: feedback.credits }"
                        >
                        </fa-icon>
                    </span>
                    <span class="feedback-title" *ngIf="feedback.title">{{ feedback.title }}</span>
                    <jhi-feedback-collapse id="feedback-text" *ngIf="feedback.text" [text]="feedback.text!" [previewText]="feedback.previewText"></jhi-feedback-collapse>
                    <jhi-hamster *ngIf="feedback.hamsterLog" [hamsterGame]="feedback.hamsterLog"></jhi-hamster>
                    <p *ngElse jhiTranslate="artemisApp.result.noFeedback">No feedback available</p>
                </div>
            </div>
        </div>
    </ng-template>
    <!-- Build logs container -->
    <ng-template #buildLogsContainer>
        <ng-container *ngIf="buildLogs?.length; else noFeedbacksContainer">
            <h4 jhiTranslate="artemisApp.result.buildError">Build Error:</h4>
            <dl class="buildoutput dl-horizontal">
                <ng-container *ngFor="let logEntry of buildLogs; let i = index">
                    <!--Don't show the timestamp again if it is the same as the last entry's.-->
                    <dt class="mb-1" *ngIf="i === 0 || logEntry.time !== buildLogs[i - 1].time">{{ logEntry.time | artemisDate : 'short' : true }}</dt>
                    <dd
                        [class.text-danger]="logEntry.type === BuildLogType.ERROR"
                        [class.font-weight-bold]="logEntry.type === BuildLogType.ERROR"
                        [class.text-warning]="logEntry.type === BuildLogType.WARNING"
                        [class.mb-3]="i + 1 < buildLogs.length && logEntry.time !== buildLogs[i + 1].time"
                    >
                        {{ logEntry.log }}
                    </dd>
                </ng-container>
            </dl>
        </ng-container>
    </ng-template>
    <!-- No content fallback -->
    <ng-template #noFeedbacksContainer>
        <pre jhiTranslate="artemisApp.result.noResultDetails">No result details available</pre>
    </ng-template>
    <!-- Show and link to the commit hash in case of programming exercise results -->
    <p *ngIf="exerciseType === ExerciseType.PROGRAMMING && result.submission">
        <span>{{ 'artemisApp.result.linkedCommit' | artemisTranslate }}</span>
        <a *ngIf="commitUrl; else commitWithoutLink" href="{{ commitUrl }}" target="_blank" rel="noopener noreferrer">{{ commitHash }}</a>
        <ng-template #commitWithoutLink>{{ commitHash }}</ng-template>
    </p>
</div>
<div class="modal-footer">
    <button id="feedback-close" type="button" class="btn btn-outline-dark" (click)="activeModal.close('Close click')">Close</button>
</div>
