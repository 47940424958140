<div class="feedback-text">
    <p *ngIf="!previewText; else longText">{{ text }}</p>
</div>

<ng-template #longText>
    <div *ngIf="isCollapsed; else opened">
        <div class="d-inline fa-angle" (click)="isCollapsed = !isCollapsed">
            <fa-icon [icon]="faAngleRight"></fa-icon>
            <strong> ({{ 'artemisApp.result.seeMore' | artemisTranslate }}) </strong>
        </div>
        <!-- Only show first line as preview -->
        <p>{{ previewText }} ...</p>
    </div>
    <ng-template #opened>
        <div class="feedback-text">
            <fa-icon class="fa-angle" [icon]="faAngleDown" (click)="isCollapsed = !isCollapsed"></fa-icon>
            <p>{{ text }}</p>
        </div>
    </ng-template>
</ng-template>
