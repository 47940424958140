<jhi-result
    id="result"
    [exercise]="exercise"
    [result]="result"
    [participation]="participation"
    [isBuilding]="isBuilding"
    [short]="short"
    [showUngradedResults]="showUngradedResults"
    [showBadge]="showBadge"
    [showIcon]="showIcon"
    [showTestDetails]="showTestNames"
    [missingResultInfo]="missingResultInfo"
></jhi-result>
