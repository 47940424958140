import { Component, ElementRef, Input, OnChanges, OnInit, ViewEncapsulation } from '@angular/core';
import { Simulator, mainSimulation } from './WebHamster/out/index';

@Component({
    selector: 'jhi-hamster',
    templateUrl: './WebHamster/only-playfield.html',
    styleUrls: ['./hamster.scss'],
    encapsulation: ViewEncapsulation.ShadowDom,
})
export class HamsterComponent implements OnInit, OnChanges {
    @Input() hamsterGame = '';

    private client: Simulator | undefined = undefined;

    constructor(private element: ElementRef) {}

    ngOnChanges() {
        console.log('changed');
        console.log(JSON.parse(this.hamsterGame));
        const mainElem = [...this.element.nativeElement.shadowRoot.children].find((e) => e.classList.contains('webHamsterMainContainer'));
        console.log(mainElem);
        if (!this.client) {
            this.client = mainSimulation(mainElem);
        }
        this.client.addGame('0', JSON.parse(this.hamsterGame));
    }

    ngOnInit() {
        console.log('init');
        if (!this.client) {
            this.client = mainSimulation(this.element.nativeElement);
        }
    }
}
