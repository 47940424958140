<div [id]="'exercise-card-' + exercise.id" class="course-exercise-row row align-items-center mb-2 mt-2 position-relative" [class.guided-tour]="hasGuidedTour">
    <a class="stretched-link" [routerLink]="['/courses', course.id!, 'exercises', exercise.id!]"></a>
    <div class="col-auto d-none d-sm-block">
        <a class="exercise-row-icon" [routerLink]="['/courses', course.id!, 'exercises', exercise.id!]">
            <fa-icon *ngIf="exercise.type" [icon]="getIcon(exercise.type)" size="2x" [ngbTooltip]="getIconTooltip(exercise.type) | artemisTranslate"></fa-icon>
        </a>
    </div>
    <div class="col">
        <div class="row">
            <div class="col-auto d-sm-none">
                <h4 class="fw-medium">
                    <fa-icon *ngIf="exercise.type" [icon]="getIcon(exercise.type)"></fa-icon>
                </h4>
            </div>
            <div class="col-sm col">
                <h4 class="fw-medium">{{ exercise.title }}</h4>
            </div>
            <jhi-submission-result-status
                *ngIf="!isPresentationMode"
                [exercise]="exercise"
                [studentParticipation]="gradedStudentParticipation"
                [triggerLastGraded]="false"
                [short]="true"
                class="result"
            >
            </jhi-submission-result-status>
        </div>
        <div class="row" style="justify-content: space-between">
            <jhi-exercise-details-student-actions
                jhiOrionFilter
                [showInOrionWindow]="false"
                [actionsOnly]="true"
                [smallButtons]="true"
                [courseId]="course.id!"
                [smallColumns]="true"
                [equalColumns]="false"
                [exercise]="exercise"
                [ngClass]="'mt-2 mb-2 raised-actions'"
                *ngIf="!isPresentationMode"
            ></jhi-exercise-details-student-actions>
            <div class="exercise-tags col-auto col-sm d-flex justify-content-center mt-2">
                <h4 class="fw-medium" *ngIf="exercise.releaseDate?.isAfter(dayjs())">
                    <a [routerLink]="['/courses', course.id!, 'exercises', exercise.id!]"><jhi-not-released-tag class="me-1" [exercise]="exercise"></jhi-not-released-tag></a>
                </h4>
                <h4 class="fw-medium" *ngIf="!isPresentationMode">
                    <span class="badge bg-success me-1" [hidden]="!asQuizExercise(exercise).isActiveQuiz">{{
                        'artemisApp.courseOverview.exerciseList.live' | artemisTranslate
                    }}</span>
                </h4>
                <h4 class="fw-medium" *ngFor="let category of exerciseCategories">
                    <span class="badge text-white me-1" [ngStyle]="{ backgroundColor: category.color }">{{ category.category }}</span>
                </h4>
                <h4 class="fw-medium" *ngIf="exercise.difficulty">
                    <jhi-difficulty-badge class="me-1" [exercise]="exercise" [showNoLevel]="false"></jhi-difficulty-badge>
                </h4>
                <h4 class="fw-medium" *ngIf="exercise.includedInOverallScore !== IncludedInOverallScore.INCLUDED_COMPLETELY">
                    <a [routerLink]="['/courses', course.id!, 'exercises', exercise.id!]">
                        <jhi-included-in-score-badge [includedInOverallScore]="exercise.includedInOverallScore"></jhi-included-in-score-badge>
                    </a>
                </h4>
            </div>
            <div class="col-sm-auto mb-2" *ngIf="dueDate; else noDate" [ngClass]="getUrgentClass(dueDate) || ''">
                <span class="d-none d-sm-inline"> {{ 'artemisApp.exercise.dueDate' | artemisTranslate }}: </span>
                {{ dueDate | artemisTimeAgo }}
            </div>
            <ng-template #noDate>
                <div class="col-auto mb-2">{{ 'artemisApp.courseOverview.exerciseList.noDueDate' | artemisTranslate }}</div>
            </ng-template>
        </div>
    </div>
</div>
