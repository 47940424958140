<ng-container>
    <button
        jhi-exercise-action-button
        [buttonIcon]="faRedo"
        [jhiFeatureToggle]="FeatureToggle.ProgrammingExercises"
        [buttonLabel]="'artemisApp.exerciseActions.practice' | artemisTranslate"
        [buttonLoading]="startingPracticeMode"
        [smallButton]="smallButtons"
        [hideLabelMobile]="false"
        [ngbPopover]="popContent"
        [autoClose]="'outside'"
        placement="right auto"
        container="body"
    ></button>
    <ng-template #popContent>
        <div class="start-practice-popover">
            <h5>{{ 'artemisApp.exerciseActions.practiceMode.title' | artemisTranslate }}</h5>
            <p>{{ 'artemisApp.exerciseActions.practiceMode.explanation' | artemisTranslate }}</p>
            <p *ngIf="gradedStudentParticipation">{{ 'artemisApp.exerciseActions.practiceMode.repositoryChoice' | artemisTranslate }}</p>
            <div class="button-footer">
                <button
                    jhi-exercise-action-button
                    [buttonIcon]="faRedo"
                    [jhiFeatureToggle]="FeatureToggle.ProgrammingExercises"
                    [buttonLabel]="
                        (gradedStudentParticipation ? 'artemisApp.exerciseActions.practiceMode.practiceWithTemplate' : 'artemisApp.exerciseActions.practice') | artemisTranslate
                    "
                    [buttonLoading]="startingPracticeMode"
                    [smallButton]="smallButtons"
                    [hideLabelMobile]="false"
                    (click)="startPractice(false)"
                ></button>
                <button
                    jhi-exercise-action-button
                    class="ms-2"
                    [buttonIcon]="faRedo"
                    [jhiFeatureToggle]="FeatureToggle.ProgrammingExercises"
                    [buttonLabel]="'artemisApp.exerciseActions.practiceMode.practiceWithGradedParticipation' | artemisTranslate"
                    [buttonLoading]="startingPracticeMode"
                    [smallButton]="smallButtons"
                    [hideLabelMobile]="false"
                    *ngIf="gradedStudentParticipation"
                    (click)="startPractice(true)"
                ></button>
            </div>
        </div>
    </ng-template>
</ng-container>
