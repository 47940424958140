<ng-template #controls>
    <div class="course-overview-controls d-none d-sm-block" *ngIf="course?.lectures && course!.lectures!.length > 0">
        <div ngbDropdown placement="bottom-right auto" class="d-inline-block">
            <button class="btn btn-outline-primary" id="dropdownBasic1" ngbDropdownToggle>
                {{ 'artemisApp.courseOverview.lectureList.sortLectures' | artemisTranslate }}
            </button>
            <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
                <button class="dropdown-item" (click)="groupLectures(DUE_DATE_DESC)">
                    <fa-icon [icon]="faSortAmountUp"></fa-icon>
                    {{ 'artemisApp.courseOverview.exerciseList.newFirst' | artemisTranslate }}
                </button>
                <button class="dropdown-item" (click)="groupLectures(DUE_DATE_ASC)">
                    <fa-icon [icon]="faSortAmountDown"></fa-icon>
                    {{ 'artemisApp.courseOverview.exerciseList.oldFirst' | artemisTranslate }}
                </button>
            </div>
        </div>
    </div>
</ng-template>

<div class="row">
    <div class="col-12 col-md-8">
        <div class="mt-3 position-relative" *ngIf="course">
            <div class="exercise-row-container mb-3" *ngFor="let weekKey of weeklyIndexKeys">
                <div
                    class="control-label"
                    [ngClass]="{ 'text-primary': weeklyLecturesGrouped[weekKey]!.isCurrentWeek }"
                    (click)="weeklyLecturesGrouped[weekKey]!.isCollapsed = !weeklyLecturesGrouped[weekKey]!.isCollapsed"
                >
                    <fa-icon class="pe-3" [icon]="weeklyLecturesGrouped[weekKey]!.isCollapsed ? faAngleDown : faAngleUp"></fa-icon>
                    <span *ngIf="weeklyLecturesGrouped[weekKey].start && weeklyLecturesGrouped[weekKey].end">
                        <strong>{{ weeklyLecturesGrouped[weekKey].start | artemisDate : 'long-date' }}</strong> -
                        <strong>{{ weeklyLecturesGrouped[weekKey].end | artemisDate : 'long-date' }}</strong>
                    </span>
                    <span *ngIf="!weeklyLecturesGrouped[weekKey].start || !weeklyLecturesGrouped[weekKey].end">
                        {{ 'artemisApp.courseOverview.exerciseList.noDateAssociated' | artemisTranslate }}
                    </span>
                    <span
                        class="ms-2"
                        jhiTranslate="artemisApp.courseOverview.lectureList.lectureGroupHeader"
                        [translateValues]="{ total: weeklyLecturesGrouped[weekKey]!.lectures.length }"
                    >
                        Exercises: {{ weeklyLecturesGrouped[weekKey]!.lectures.length }}
                    </span>
                </div>
                <div *ngIf="!weeklyLecturesGrouped[weekKey]!.isCollapsed">
                    <jhi-course-lecture-row
                        class="pb-1"
                        [lecture]="lecture"
                        [course]="course"
                        *ngFor="let lecture of weeklyLecturesGrouped[weekKey]!.lectures"
                    ></jhi-course-lecture-row>
                </div>
                <div class="collapsed"></div>
            </div>
            <div *ngIf="!course?.lectures">
                <div class="row">
                    <div class="col">
                        {{ 'artemisApp.courseOverview.lectureList.noLectures' | artemisTranslate }}
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="col-12 col-md-4 course-information">
        <jhi-side-panel [panelHeader]="'artemisApp.courseOverview.exerciseList.details.courseInformation' | artemisTranslate">
            <div class="row mb-1">
                <div class="col-8">{{ 'artemisApp.courseOverview.lectureList.totalLectures' | artemisTranslate }}</div>
                <div class="col-4">{{ course?.lectures ? course!.lectures!.length : 0 }}</div>
            </div>
            <div class="row mb-1" *ngIf="course?.startDate">
                <div class="col-8">{{ 'artemisApp.courseOverview.exerciseList.details.startDate' | artemisTranslate }}</div>
                <div class="col-4">{{ course?.startDate | artemisDate }}</div>
            </div>
            <div class="row" *ngIf="course?.endDate">
                <div class="col-8">{{ 'artemisApp.courseOverview.exerciseList.details.endDate' | artemisTranslate }}</div>
                <div class="col-4">{{ course?.endDate | artemisDate }}</div>
            </div>
        </jhi-side-panel>
    </div>
</div>
