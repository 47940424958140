<div>
    <div *ngIf="course">
        <jhi-header-course [course]="course"></jhi-header-course>
        <div class="tab-bar tab-bar-overview" id="tab-bar">
            <div class="col-12" [ngClass]="{ 'no-indent': !controlConfiguration?.useIndentation, 'col-lg-8': controlConfiguration?.useIndentation }">
                <div>
                    <div
                        id="exam-tab"
                        *ngIf="course.exams && hasVisibleExams()"
                        jhiOrionFilter
                        [showInOrionWindow]="false"
                        class="tab-item exams"
                        routerLink="exams"
                        routerLinkActive="active"
                    >
                        <b jhiTranslate="artemisApp.courseOverview.menu.exams">Exams</b>
                    </div>
                    <div class="tab-item exercises" routerLink="exercises" routerLinkActive="active">
                        <b jhiTranslate="artemisApp.courseOverview.menu.exercises">Exercise</b>
                    </div>
                    <div *ngIf="course.lectures" jhiOrionFilter [showInOrionWindow]="false" class="tab-item lectures" routerLink="lectures" routerLinkActive="active">
                        <b jhiTranslate="artemisApp.courseOverview.menu.lectures">Lectures</b>
                    </div>
                    <div
                        *ngIf="hasLearningGoals()"
                        jhiOrionFilter
                        [showInOrionWindow]="false"
                        class="tab-item learning-goals"
                        routerLink="learning-goals"
                        routerLinkActive="active"
                    >
                        <b jhiTranslate="artemisApp.courseOverview.menu.learningGoals">Learning Goals</b>
                    </div>
                    <div jhiOrionFilter [showInOrionWindow]="false" class="guided-tour tab-item statistics" routerLink="statistics" routerLinkActive="active">
                        <b jhiTranslate="artemisApp.courseOverview.menu.statistics">Statistics</b>
                    </div>
                    <div *ngIf="course.postsEnabled" jhiOrionFilter [showInOrionWindow]="false" class="tab-item discussion" routerLink="discussion" routerLinkActive="active">
                        <b jhiTranslate="artemisApp.courseOverview.menu.communication">Communication</b>
                    </div>
                    <div *ngIf="course.postsEnabled" jhiOrionFilter [showInOrionWindow]="false" class="tab-item discussion" routerLink="messages" routerLinkActive="active">
                        <b jhiTranslate="artemisApp.courseOverview.menu.messages">Messages</b>
                    </div>
                    <div
                        *ngIf="hasTutorialGroups()"
                        [jhiFeatureToggleHide]="FeatureToggle.TutorialGroups"
                        jhiOrionFilter
                        [showInOrionWindow]="false"
                        class="tab-item tutorial-groups"
                        routerLink="tutorial-groups"
                        routerLinkActive="active"
                    >
                        <b jhiTranslate="artemisApp.courseOverview.menu.tutorialGroups">Tutorial Groups</b>
                    </div>
                </div>
                <div class="controls">
                    <button class="btn btn-primary btn-md ms-1" (click)="loadCourse(true)" [disabled]="refreshingCourse">
                        <fa-icon [icon]="faSync" [spin]="refreshingCourse"></fa-icon>
                        <span class="d-none d-sm-inline" jhiTranslate="artemisApp.exercise.refresh">Refresh</span>
                    </button>
                    <ng-container #controlsViewContainer></ng-container>
                </div>
            </div>
        </div>
        <div class="course-body-container">
            <router-outlet (activate)="onSubRouteActivate($event)" (deactivate)="onSubRouteDeactivate()"></router-outlet>
            <div class="refresh-overlay" [class.active]="refreshingCourse">
                <fa-icon size="lg" [icon]="faCircleNotch" [spin]="true"></fa-icon>
            </div>
        </div>
    </div>
</div>
