<button
    [ngClass]="['jhi-btn', 'btn', btnType, btnSize]"
    [type]="shouldSubmit ? 'submit' : 'button'"
    ngbTooltip="{{ tooltip | artemisTranslate }}"
    (click)="onClick.emit($event)"
    [jhiFeatureToggle]="featureToggle"
    [overwriteDisabled]="disabled || isLoading"
>
    <fa-icon class="jhi-btn__loading" *ngIf="isLoading" [icon]="faCircleNotch" [spin]="true" size="sm"></fa-icon>
    <fa-icon class="jhi-btn__icon" *ngIf="icon && !isLoading" [icon]="icon" size="sm"></fa-icon>
    <span class="jhi-btn__title" [class.ms-1]="icon || isLoading" *ngIf="title" [jhiTranslate]="title"></span>
</button>
